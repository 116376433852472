<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  icon: string
  title?: string
}>()

const iconClass = computed(() => (props.icon.startsWith('bi-') ? props.icon : `bi-${props.icon}`))
</script>

<template>
  <i
    :class="iconClass"
    role="img"
    :title="title"
  />
</template>

<style lang="scss" scoped>
$bootstrap-icons-font-src:
  url('bootstrap-icons/font/fonts/bootstrap-icons.woff2') format('woff2'),
  url('bootstrap-icons/font/fonts/bootstrap-icons.woff') format('woff');

@import 'bootstrap-icons';
</style>
